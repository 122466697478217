<template>
  <div class="home">
    
    <HeroSlider />

    <ProductRecommendation />
    <!-- <ProductDiscount /> -->
    <ProductNewArrival />
    <!-- <Banners /> -->
    <ProductBestSeller />
    <!-- <BlogPost /> -->
    <LogoPartner />


  </div> 
</template>

<script>
// @ is an alias to /src

import HeroSlider from '@/components/HeroSlider.vue'
import ProductRecommendation from '@/components/ProductRecommendation.vue'
// import ProductDiscount from '@/components/ProductDiscount.vue'
import ProductNewArrival from '@/components/ProductNewArrival.vue'
// import Banners from '@/components/Banners.vue'
import ProductBestSeller from '@/components/ProductBestSeller.vue'
// import BlogPost from '@/components/BlogPost.vue'
import LogoPartner from '@/components/LogoPartner.vue'


export default {
  name: 'Home',
  components: {
    HeroSlider,
    ProductRecommendation,
    // ProductDiscount,
    ProductNewArrival,
    // Banners,
    ProductBestSeller,
    // BlogPost,
    LogoPartner
  

  },
  mounted(){
    console.log(process.env)
  }

}

</script>



<style scoped>

</style>

<template>
<div class="accountprofile">
    <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="#"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap"><a href="#">Account</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Profile info</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">Profile info</h1>
        </div>
      </div>
    </div>
    <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-3">
      <div class="row">

        <!-- Sidebar-->
        <AccountSidebarMenu />

        <!-- Content  -->
        <section class="col-lg-8">
          <!-- Toolbar-->
          <div class="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
            <h6 class="font-size-base text-light mb-0">Update you profile details below:</h6><button class="btn btn-primary btn-sm" v-if="!guest" @click="logout"><i class="czi-sign-out mr-2"></i>Sign out</button>
          </div>
          <!-- Profile form-->
          <form>
            <!-- <div class="bg-secondary rounded-lg p-4 mb-4">
              <div class="media align-items-center"><img src="img/shop/account/avatar.jpg" width="90" alt="Susan Gardner">
                <div class="media-body pl-3">
                  <button class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="czi-loading mr-2"></i>Change avatar</button>
                  <div class="p mb-0 font-size-ms text-muted">Upload JPG, GIF or PNG image. 300 x 300 required.</div>
                </div>
              </div>
            </div> -->
            <div class="row" >
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-country">Country</label>
                  <select name="country" class="form-control custom-select" id="checkout-country" aria-readonly disabled>
                    <!-- <option>Choose country</option> -->
                    <option value="1">Indonesia</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row" >
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-fn">Name</label>
                  <input class="form-control" type="text" value="" v-model="user.name" >
                </div>
              </div>
              <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-ln">Last Name</label>
                  <input class="form-control" type="text" id="account-ln" value="Gardner">
                </div>
              </div> -->
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-email">Email Address</label>
                  <input class="form-control" type="email" id="account-email" value="" v-model="user.email" disabled>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-phone">Phone Number</label>
                  <input class="form-control" type="text" id="account-phone" value="" v-model="user.phone" required>
                </div>
              </div>
              
              <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-pass">New Password</label>
                  <div class="password-toggle">
                    <input class="form-control" type="password" id="account-pass">
                    <label class="password-toggle-btn">
                      <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator"></i><span class="sr-only">Show password</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-confirm-pass">Confirm Password</label>
                  <div class="password-toggle">
                    <input class="form-control" type="password" id="account-confirm-pass">
                    <label class="password-toggle-btn">
                      <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator"></i><span class="sr-only">Show password</span>
                    </label>
                  </div>
                </div>
              </div> -->

      
            <div class="col-sm-12">
              <div class="form-group">
                <label for="checkout-address-1">Address </label>
                <input class="form-control" type="text" id="checkout-address-1" v-model="user.address">
              </div>
            </div>


            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-province">Province</label>
                <select name="province" class="form-control custom-select" id="checkout-province" aria-readonly  v-model='user.province_id' @change='citiesByProvince()' >
                  <option v-if="user.province_id<1" value="" selected>Choose province</option>
                  <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.province }}</option>
                </select>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-city">City</label>
                <select class="form-control custom-select" id="checkout-city" v-model='user.city_id'>
                  <option value="">Choose city</option>
                  <option class="py-1" v-for="city in citiesByProvince" :key="city.id" :value="city.id">{{ city.city }}</option>
                </select>
              </div>
            </div>
      
              <div class="col-12">
                <hr class="mt-2 mb-3">
                <div class="d-flex flex-wrap justify-content-between align-items-center">
                  <div class="custom-control custom-checkbox d-block">
                    <!-- <input class="custom-control-input" type="checkbox" id="subscribe_me" checked>
                    <label class="custom-control-label" for="subscribe_me">Subscribe me to Newsletter</label> -->
                  </div>
                  <button @click="update()" class="btn btn-primary mt-3 mt-sm-0" type="button">Update profile</button>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
</div>
</template>

<script>
import AccountSidebarMenu from '@/components/AccountSidebarMenu.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AccountProfile',
  components: {
    AccountSidebarMenu
  },
  data() {
    return {
        name: this.user.name,
        email: this.user.email,
        address: this.user.address,
        phone: this.user.phone,
        province_id: this.user.province_id,
        city_id: this.user.city_id
      } 
  },
   methods: { 
      ...mapActions({
        setDialogStatus   : 'dialog/setStatus',
        setDialogComponent: 'dialog/setComponent',
        setAuth           : 'auth/set',
        setAlert          : 'alert/set',
        setProvinces      : 'region/setProvinces',
        setCities         : 'region/setCities',
      }),
      logout(){
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }
        this.axios.post('/logout', {}, config)
          .then(() => {
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                status : true,
                color  : 'success',
                text  : 'Logout successfully',
              })
            this.$router.push( { path: '/' })
          })
          .catch((error) => {
            let {data} = error.response   
            this.setAlert({
                status : true,
                color  : 'danger',
                text  : data.message,
            })
          })
      },
      update(){
        let formData = new FormData()
        formData.set('name', this.user.name)
        formData.set('email', this.user.email)
        formData.set('address', this.user.address)
        formData.set('phone', this.user.phone)
        formData.set('province_id', this.user.province_id)
        formData.set('city_id', this.user.city_id)

        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }
        this.axios.post('/update', formData, config)
          .then(() => {
            this.setAlert({
                status : true,
                color  : 'success',
                text  : 'Update data successfully',
              })
          })
          .catch((error) => {
            let {data} = error.response   
            this.setAlert({
                status : true,
                color  : 'danger',
                text  : data.message,
            })
          })
      }


    },
    
   computed: {
    ...mapGetters({
      provinces     : 'region/provinces',
      cities        : 'region/cities',
      countCart     : 'cart/count',
      guest         : 'auth/guest',
      user          : 'auth/user',
      dialogStatus  : 'dialog/status',
      currentComponent: 'dialog/component',
    }),
      citiesByProvince(){
        let province_id = this.user.province_id
        return this.cities.filter((city) => {           
            if (city.province_id==province_id) return city
        })
      },
   },

    created(){
        this.name = this.user.name
        this.name = this.user.email
        this.address = this.user.address
        this.phone = this.user.phone
        this.city_id = this.user.city_id
        this.province_id = this.user.province_id

        if(this.provinces && this.provinces.length==0){
            this.axios.get('/provinces')
            .then((response) => {
                let { data } = response.data
                this.setProvinces(data)
                
            })

            this.axios.get('/cities')
            .then((response) => {
                let { data } = response.data
                this.setCities(data)
            })
        }
    }

}
</script>
<template>
    <!-- Products grid (Our Recomendation)-->
    <section class="container pt-md-3 pb-5 mb-md-3">
      <h2 class="h3 text-center my-5">Our Recomendation</h2>
      <div class="row pt-4 mx-n2" v-if="products.length > 0">
        <!-- Product-->
        <div class="col-6 col-md-4 col-lg-3 px-2 mb-4" v-for="itemProduct in products" v-bind:key="itemProduct.id">
          <div class="card products product-card">
                  <small>CODE: {{ itemProduct.kode }}</small>
                 <button v-if="isInclude(itemProduct.id)" class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist" @click="savewishlist(itemProduct.id)">
                    <i class="fa fa-heart wishlist-active" aria-hidden="true" style="color:red;"  ></i>
                    </button> 

                  <button  v-else class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist" @click="savewishlist(itemProduct.id)">
                    <i class="fa fa-heart" aria-hidden="true"></i>
                    </button>
                  <router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" class="card-img-top d-block overflow-hidden"  >
                  <div class="p-0 w-100 d-flex align-items-center" style="height:200px; background:#ccc;">
                    <img :src="base_url + '/storage/images/products/' + itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" >
                  
                  </div>
                  </router-link> 
                   
                  <div class="card-body py-2 px-0">
                    <h4 class="product-title "><router-link v-bind:to="'/product/detail/'+itemProduct.id" ><b>{{ itemProduct.name }}</b></router-link><br>
                    <small>
                      <strong>{{ itemProduct.carats }}</strong><br>
                      {{ itemProduct.dimensions }}
                    </small>
                    
                    <div class="d-flex justify-content-between mt-0">
                      <div class="product-price"><span class="text-accent font-size-lg">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span></div>
                      <!-- <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i></div> -->
                    </div>
                    </h4>
                  </div>

                  <!-- <div class="card-body card-body-hidden" v-if="itemProduct.quantity > 0">
                  <a @click="saveKeranjang(itemProduct.id, itemProduct.name, itemProduct.price, itemProduct.galleries[0].photo)"  class="btn btn-primary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast"><i class="czi-cart font-size-sm mr-1"></i>Add to Cart</a>
                  </div>
                  <div class="card-body card-body-hidden" v-else>
                    <button class="btn btn-secondary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast">Sold Out</button>
                  
                  </div> -->


                </div>
          <hr class="d-sm-none">
        </div>


      </div>

      <div class="row" v-else>
          <div class="col-md-12 text-center" >
            <p>
              Produk terbaru belum tersedia saat ini.
            </p>
          </div>
      </div>

      <!-- <div class="text-center pt-3"><a class="btn btn-outline-accent" href="/product/detail">More products<i class="czi-arrow-right ml-1"></i></a></div> -->
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'ProductRecommendation',
    data() {
      return {
        products: [],
        keranjangUser:[],
         wishlists:[],
          base_url: process.env.VUE_APP_API_URL,
      }
    }, 
     created(){
        let config = {
            headers: {
                'Authorization': 'Bearer ' + this.user.api_token,
            },
          }
      
      this.axios.get('/my-wishlist',config)
       .then((response) => {
          let {data } = response.data
          this.wishlists = data
        })
       .catch((error) => {
          let {data} = error.response
          this.setAlert({
              status : true,
              text  : data.message,
              color  : 'danger',
        }) })

    },

     mounted() {
  
        this.axios.get("/products-recommendation?limit=4")
        .then(res => (this.products = res.data.data.data))
        .catch((error) => {
            console.log(error)
        })

        // .then(res => (this.products = res.data.data.data))
        // //  eslint-disable-next-line no-console
        // .catch(err => console.log(err));

      if (localStorage.getItem('keranjangUser')) {
        try {
          this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
        } catch(e) {
          localStorage.removeItem('keranjangUser');
        }
      }
    },
    methods: {
      ...mapActions({
          setAlert  : 'alert/set',
      }),

       isInclude(products_id) {
        return this.anotherArrayName.includes(products_id)
    },
 
      saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct) {
        var productStored = {
          "id": idProduct,
          "name": nameProduct,
          "price": priceProduct,
          "photo": photoProduct
        }
        this.keranjangUser.push(productStored);
        const parsed = JSON.stringify(this.keranjangUser);
        localStorage.setItem('keranjangUser', parsed);

        window.location.reload();
      },

      savewishlist(products_id){
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }

        let formData = {
            'products_id' : products_id,
        }

        this.axios.post('/make-wishlist', formData, config)
          .then((response) => {
            this.info = response.data.message
            this.color = response.data.status
          let {data } = response.data
          this.items = data
            this.setAlert({
                status : true,
                color  : this.color,
                text  : this.info,
              })
            // this.$router.push( { path: '/' })
          })
          .catch((error) => {
            let {data} = error.response   
            this.setAlert({
                status : true,
                color  : 'danger',
                text  : data.message,
            })
          })
      },
    },

    computed: {
         ...mapGetters({
          user    : 'auth/user',
          }),
            anotherArrayName() {
              return this.wishlists.map(item => item.products_id)
          }
    }
   
};
</script>
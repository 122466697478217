<template>

     <!-- Cart dropdown-->
                <div class="dropdown-menu dropdown-menu-right" style="width: 20rem;">
                  <div class="widget widget-cart px-3 pt-2 pb-3">
                    <div style="height: 15rem;" data-simplebar data-simplebar-auto-hide="false" v-if="carts.length > 0">

                      <div class="widget-cart-item pb-2 border-bottom" v-for="keranjang in carts" :key="keranjang.id" >
                        <button @click="removeItem(keranjang.id)" class="close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">&times;</span></button>
                        <div class="media align-items-center">
                          <router-link class="d-block mr-2" v-bind:to="'/product/detail/'+keranjang.id"><img width="64"  :src="base_url + '/storage/images/products/' + keranjang.photo" alt="Product"/></router-link>
                          <div class="media-body">
                            <h6 class="widget-product-title"><router-link v-bind:to="'/product/detail/'+keranjang.id" >{{ keranjang.name }}</router-link></h6>
                            <div class="widget-product-meta"><span class="text-accent mr-2">Rp {{ keranjang.price.toLocaleString('id-ID') }}</span><span class="text-muted">x {{ keranjang.quantity }}</span></div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div style="height: 5rem;" data-simplebar data-simplebar-auto-hide="false" v-else>
                        <div class="media align-items-center">
                          <div class="alert alert-warning d-block w-100" role="alert">
                            Shopping cart empty!
                          </div>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
                      <div class="font-size-sm mr-2 py-2"><span class="text-muted">Total Price ({{  totalQuantity  }} items)</span><br>
                      <span class="text-accent font-size-base ml-1">
                        Rp {{ totalHarga.toLocaleString('id-ID') }}</span></div></div><router-link class="btn btn-primary btn-sm btn-block" to="/cart"><i class="czi-card mr-2 font-size-base align-middle"></i>Checkout</router-link>
                  </div>

                </div>
       
</template>

<script>  
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'CartDropdown',
    data() {
    return {
      keranjangUser: [],
      urlwindow: window.location.origin,
      base_url: process.env.VUE_APP_API_URL,
    }

  },
      
  methods: {
      ...mapActions({
          setDialogStatus   : 'dialog/setStatus',
          setDialogComponent: 'dialog/setComponent',
          setAuth     : 'auth/set',
          setAlert    : 'alert/set',
          addCart     : 'cart/add',
          removeCart  : 'cart/remove',
          setCart     : 'cart/set',
        }),
    removeItem(idx) {
      let keranjangUserStorage = JSON.parse(localStorage.getItem("keranjangUser"));
      let itemKeranjangUserStorage = keranjangUserStorage.map(itemKeranjangUserStorage => itemKeranjangUserStorage.id);
      let index = itemKeranjangUserStorage.findIndex(id => id == idx);
      this.keranjangUser.splice(index,1);

      const parsed = JSON.stringify(this.keranjangUser);
      localStorage.setItem("keranjangUser", parsed);
      window.location.reload();
    }
  },
  mounted() {
      if (localStorage.getItem('keranjangUser')) {
      try {
        this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
      } catch(e) {
        localStorage.removeItem('keranjangUser');
      }
    }
  },

  computed: {
     ...mapGetters({
        carts         : 'cart/carts',
        countCart     : 'cart/count',
        totalPrice    : 'cart/totalPrice',
        totalQuantity : 'cart/totalQuantity',
        totalWeight   : 'cart/totalWeight',
        guest         : 'auth/guest',
        user          : 'auth/user',
        dialogStatus  : 'dialog/status',
        currentComponent: 'dialog/component',
      }),
    totalHarga() {
      return this.carts.reduce(function(items, data){
        return items + data.price;
      },0);
    }
  }
    
}
</script>
<template>
<div class="accountwishlist">
     <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap"><a href="#">Account</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Wishlist</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">My wishlist</h1>
        </div>
      </div>
    </div>
    <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-4">
      <div class="row">
        
        <AccountSidebarMenu />
       

        <!-- Content  -->
        <section class="col-lg-8">
          <!-- Toolbar-->
          <div class="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
            <h6 class="font-size-base text-light mb-0">List of items you added to wishlist:</h6><a class="btn btn-primary btn-sm" href="account-signin.html"><i class="czi-sign-out mr-2"></i>Sign out</a>
          </div>
          <!-- Wishlist-->
          <!-- Item-->
          <div v-if="items.length>0">
          <div class="d-sm-flex justify-content-between mt-lg-4 mb-4 pb-3 pb-sm-2 border-bottom" v-for="(item, index) in items" :key="item.id">
            <div class="media media-ie-fix d-block d-sm-flex text-center text-sm-left"><router-link v-bind:to="'/product/detail/'+item.product.id+'/'+item.product.slug"  class="d-inline-block mx-auto mr-sm-4" style="width: 10rem;"><img v-bind:src="item.galleries[0].photo" alt="Product"></router-link>
              <div class="media-body pt-2">
                <h3 class="product-title font-size-base mb-2"><router-link v-bind:to="'/product/detail/'+item.product.id+'/'+item.product.slug">{{ item.product.name }}</router-link></h3>
                <div class="font-size-sm"><span class="text-muted mr-2">Carats:</span>{{ item.product.carats }}</div>
                <div class="font-size-sm"><span class="text-muted mr-2">Dimensions:</span>{{ item.product.dimensions }}</div>
                <div class="font-size-lg text-accent pt-2">Rp. {{item.product.price.toLocaleString('id-ID') }}</div>
              </div>
            </div>
            <div class="pt-2 pl-sm-3 mx-auto mx-sm-0 text-center">
              <button class="btn btn-outline-danger btn-sm px-4" type="button" @click="remove(index,item.id )"><i class="czi-trash mr-2"></i>Remove</button>
              <button class="btn btn-primary btn-sm btn-block mb-2 mt-2" type="button" data-toggle="toast" data-target="#cart-toast"><i class="czi-cart font-size-sm mr-2" @click="buy(items.index)"></i>Add to Cart</button>
            </div>
          </div>
          </div>

          <div v-else class="d-sm-flex justify-content-between mt-lg-4 mb-4 pb-3 pb-sm-2 ">
            <div class="alert alert-warning w-100" role="alert">
              {{info}}
            </div>
           
          </div>
        
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSidebarMenu from '@/components/AccountSidebarMenu.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AccountWishlist',
  components: {
    AccountSidebarMenu

  },
  data() {
    return {
       items:[],
        info: null
        // nama_product: this.name,
        // carats: this.carats,
        // dimensions: this.dimensions,
        // price:this.price
      
    }

   
  },
    computed: {
        ...mapGetters({
          user    : 'auth/user',
          carts: 'cart/carts',
  }) },
   methods: {
      ...mapActions({
         addCart   : 'cart/add',
          setAlert  : 'alert/set',
      }),
       buy(){
        this.addCart(this.items.index)
        //this.$store.dispatch('cart/add', this.book)
        this.setAlert({
            status : true,
            color  : 'success',
            text   : 'Added to cart',
        })
    },
      remove(index, id){
   
        let config = {
        headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }

        let formData = {
            'id' : id,
        }
      
        this.axios.post('/remove-wishlist/'+id, formData, config)
        .then((response) => {
            let {data } = response.data
            this.items.splice(index, 1);
            this.setAlert({
                status : true,
                text  : data.message,
                color  : 'success',
            })
          })
        .catch((error) => {
            let {data} = error.response
            this.setAlert({
                status : true,
                text  : data.message,
                color  : 'danger',
          }) })

      },
        
    },
  mounted() {
     let config = {
        headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
      

      this.axios.get('/my-wishlist',config)
       .then((response) => {
         this.info = response.data.message
          let {data } = response.data
          this.items = data
        })
       .catch((error) => {
          let {data} = error.response
          this.setAlert({
              status : true,
              text  : data.message,
              color  : 'danger',
        }) })

    },


}
</script>

<style scoped>

</style>
<template>
     <!-- Categories-->
     <div>
              <div class="widget widget-categories mb-4 pb-4 border-bottom">
                <h3 class="widget-title">Categories</h3>
                <!-- <div class="accordion mt-n1" id="shop-categories"> -->
                  <!-- Shoes-->
                  <div class="card">
                    <!-- <div class="card-header">
                      <h3 class="accordion-heading"><a class="collapsed" href="#gemstones" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="gemstones">Gemstones<span class="accordion-indicator"></span></a></h3>
                    </div> -->
                    <!-- <div class="" id="gemstones" data-parent="#shop-categories"> -->
                      <div class="card-body">
                        <!-- <div class="widget widget-links cz-filter"> -->
                          <!-- <div class="input-group-overlay input-group-sm mb-2">
                            <input class="cz-filter-search form-control form-control-sm appended-form-control" type="text" placeholder="Search">
                            <div class="input-group-append-overlay"><span class="input-group-text"><i class="czi-search"></i></span></div>
                          </div> -->
                          <ul class="widget-list cz-filter-list pt-1" style="height: 100%" data-simplebar data-simplebar-auto-hide="false" >
                            <li class="widget-list-item cz-filter-item"><router-link class="widget-list-link d-flex justify-content-between align-items-center" to="/jewelry"><span class="cz-filter-item-text"><b>View all</b></span><span class="font-size-xs text-muted ml-3"> </span></router-link></li>

                            <a  v-for="(itemCategory, index) in categories" :key="index"  :href="'/jewelry/'+itemCategory.id+'/'+itemCategory.slug" @mousedown.prevent="selectedCat(itemCategory.id)">
                            <li class="widget-list-item cz-filter-item"><span class="cz-filter-item-text"><b>{{ itemCategory.name }}</b></span><span class="font-size-xs text-muted ml-3"></span></li>
                            </a>
                            <!-- <li class="widget-list-item cz-filter-item" v-for="itemCategory in categories" :key="itemCategory.id" v-on:click="selectedCat(itemCategory.id,itemCategory.slug)"><span class="cz-filter-item-text"><b>{{ itemCategory.name }}</b></span><span class="font-size-xs text-muted ml-3"> </span></li> -->
                            <!-- <li class="widget-list-item cz-filter-item" v-for="itemCategory in categories" v-bind:key="itemCategory.id" @click="selectedCat(itemCategory.id)"><router-link class="widget-list-link d-flex justify-content-between align-items-center"  v-bind:to="{ path:'/product/gemstones/'+ itemCategory.id +'/'+ itemCategory.slug }"><span class="cz-filter-item-text"><b>{{ itemCategory.name }}</b></span><span class="font-size-xs text-muted ml-3"> </span></router-link></li> -->
                            

                          </ul>
                        <!-- </div> -->
                      </div>
                    <!-- </div> -->
                  </div>
                 
                 
                <!-- </div> -->
              </div>

              </div>
</template>

<script>

export default {
    name: 'CategoryJewelry',
    data() {
      return {
        categories: [],
        selectedsubcategory: null,

      }
    }, 
     created() {
 
        this.axios.get("/categories-jewelry")
        .then(res => (this.categories = res.data.data))
        //  eslint-disable-next-line no-console
        .catch(err => console.log(err));
    },
    methods: {
       
      selectedCat(cat){
        this.selectedsubcategory=cat
         if(cat.length != null){
                this.axios.get('products-jewelry?subcategories_id='+cat)
                .then((response) => {
                    let { data } = response.data
                    this.searchResults = data.data
                })
                .catch((error) => {
                    console.log(error)
                })
            } else {
                this.searchResults = []
            }
      }

    },
   
};
</script>
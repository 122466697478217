<template>
     <!-- Edit Address-->
    <form class="needs-validation modal fade" ref="form" id="edit-address" tabindex="-1" validate>
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit address</h5>
            <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="address-fn">Alias (ex: Home, Office, etc)</label>
                  <input class="form-control" type="text" id="address-alias" v-model="alias" required>
                  <div class="invalid-feedback">Please fill in you alias!</div>
                </div>
              </div>
             <div class="col-sm-6">
                <div class="form-group">
                  <label for="address-country">Country</label>
                  <select class="custom-select" id="address-country" disabled>
                    <option selected>Indonesia</option>
                  </select>
                  <div class="invalid-feedback">Please select your country!</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="address-ln">Recipient's Name</label>
                  <input class="form-control" type="text" id="address-name" v-model="name" required>
                  <div class="invalid-feedback">Please fill in you name!</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="address-company">Phone</label>
                  <input class="form-control" type="text" id="address-phone" v-model="phone">
                </div>
              </div>
              
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="address-line1">Shipping Address</label>
                  <input class="form-control" type="text" id="address-line1" placeholder="Address Line1*" v-model="address_line1" required>
                  <div class="invalid-feedback">Please fill in your address!</div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <input class="form-control" type="text" id="address-line2" placeholder="Address Line2" v-model="address_line2">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <input class="form-control" type="text" id="address-line3" placeholder="Address Line3" v-model="address_line3">
                </div>
              </div>

              <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-province">Province</label>
                <select name="province" class="form-control custom-select" id="checkout-province" aria-readonly  v-model='province_id' @change='citiesByProvince()' >
                  <option v-if="user.province_id<1" value="" selected>Choose province</option>
                  <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.province }}</option>
                </select>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-city">City</label>
                <select class="form-control custom-select" id="checkout-city" v-model='city_id'>
                  <option value="">Choose city</option>
                  <option class="py-1" v-for="city in citiesByProvince" :key="city.id" :value="city.id">{{ city.city }}</option>
                </select>
              </div>
            </div>

              <div class="col-12">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" id="address-primary" :value="is_default"  v-on:input="is_default = 1">
                  <label class="custom-control-label" for="address-primary">Make this address primary</label>
                </div>
              </div>
            </div>
          </div>
          
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
            <button class="btn btn-primary btn-shadow" @click="saveAddress" >Update</button>
          </div>
        </div>
      </div>
    </form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'AddressEditAdd',
    data(){
        return{
            alias:'',
            name: '',
            phone: '',
            address_line1: this.address_line1,
            address_line2: '',
            address_line3: '',
            province_id: 0,
            city_id: 0,
            is_default:0
        }
    },
    computed: {
      ...mapGetters({
        user        : 'auth/user',
        provinces   : 'region/provinces',
        cities      : 'region/cities',
      }),
      citiesByProvince(){
        let province_id = this.province_id
        return this.cities.filter((city) => {           
            if (city.province_id==province_id) return city
        })
      },
    },
    methods: {
    ...mapActions({
        setAlert      : 'alert/set',
        setAuth       : 'auth/set',
        setProvinces  : 'region/setProvinces',
        setCities     : 'region/setCities',
      }),

    saveAddress(){
        let formData = new FormData()
        formData.set('alias', this.alias)
        formData.set('name', this.name)
        formData.set('phone', this.phone)
        formData.set('address_line1', this.address_line1)
        formData.set('address_line2', this.address_line2)
        formData.set('address_line3', this.address_line3)
        formData.set('province_id', this.province_id)
        formData.set('city_id', this.city_id)
        formData.set('is_default', this.is_default)

        let config = {
            headers: {
                'Authorization': 'Bearer ' + this.user.api_token,
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
        }

        this.axios.post('/make-address', formData, config)
            .then(() => {
                 this.$router.push( { path: '/' })
                this.setAlert({
                    status : true,
                    color  : 'success',
                    text  : 'Address Added Successfully',
                })
                this.close()
                this.$router.push( { path: '/' })
            })
            .catch((error) => {
                let {data} = error.response   
                this.setAlert({
                    status : true,
                    color  : 'danger',
                    text  : data.message,
                })
            })
        },
        close() {
            this.$emit('closed', false)
        },
           clear () {
            this.$refs.form.reset()
        }
    },
    created(){
        // this.city_id = this.user.city_id
        // this.province_id = this.user.province_id

        if(this.provinces && this.provinces.length==0){
            this.axios.get('/provinces')
            .then((response) => {
                let { data } = response.data
                this.setProvinces(data)
                
            })

            this.axios.get('/cities')
            .then((response) => {
                let { data } = response.data
                this.setCities(data)
            })
        }
    }
}
</script>
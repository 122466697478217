<template>
    <!-- Page Content-->
    <div class="container py-4 py-lg-5 my-4">
      <div class="row">
        <div class="col-md-6">
          <div class="card border-0 box-shadow">
            <div class="card-body">
              <h2 class="h4 mb-1">Sign in</h2>
              <!-- <div class="py-3"> -->
                <!-- <h3 class="d-inline-block align-middle font-size-base font-weight-semibold mb-2 mr-2">With social account:</h3>
                <div class="d-inline-block align-middle"> -->
                  <!-- <a class="social-btn sb-google mr-2 mb-2" href="#" data-toggle="tooltip" title="Sign in with Google"><i class="czi-google"></i></a> -->
                  <!-- <a class="social-btn sb-facebook mr-2 mb-2" href="https://www.instagram.com/batumuliashop/" data-toggle="tooltip" title="Sign in with Facebook"><i class="czi-instagram"></i></a> -->
                  <!-- <a class="social-btn sb-twitter mr-2 mb-2" href="#" data-toggle="tooltip" title="Sign in with Twitter"><i class="czi-twitter"></i></a> -->
                  <!-- </div> -->
              <!-- </div> -->
              <hr>
              <!-- <h3 class="font-size-base pt-4 pb-2">Or using form below</h3> -->
              <form class="needs-validation" ref="form" autocomplete="off" novalidate>
                <div class="input-group-overlay form-group">
                  <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="czi-mail"></i></span></div>
                  <input class="form-control prepended-form-control" type="email" v-model="email" :rules="emailRules" placeholder="Email" required>
                </div>
                <div class="input-group-overlay form-group">
                  <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="czi-locked"></i></span></div>
                  <div class="password-toggle">
                    <input class="form-control prepended-form-control" v-model="password" 
                    :rules="passwordRules" :type="showPassword ? 'text' : 'password'" placeholder="Password" required>
                    
                    <!-- <label class="password-toggle-btn" @click="showPassword = true">
                      <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator"></i><span class="sr-only">Show password</span>
                    </label> -->
                  <label class="password-toggle-btn" @click="showPassword = true" >
                    <input class="custom-control-input" type="checkbox" >
                    <i class="czi-eye password-toggle-indicator"></i>
                    <span class="sr-only">Show password</span>
                  </label>
                  <label class="password-toggle-btn" @click="showPassword = false" v-if="showPassword">
                    <input class="custom-control-input" type="checkbox" >
                    <i class="czi-eye password-toggle-indicator"></i>
                    <span class="sr-only">Show password</span>
                  </label>
                  </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between">
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" type="checkbox" checked id="remember_me">
                    <label class="custom-control-label" for="remember_me">Remember me</label>
                  </div>
                  <!-- <a class="nav-link-inline font-size-sm" href="account-password-recovery.html">Forgot password?</a> -->
                </div>
                <hr class="mt-4">
                <div class="text-right pt-4">
                  <button class="btn btn-primary" @click="submit" ><i class="czi-sign-in mr-2 ml-n21"></i>Sign In</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6 pt-4 mt-3 mt-md-0">
          <h2 class="h4 mb-3">No account? Sign up</h2>
          <p class="font-size-sm text-muted mb-4">Registration takes less than a minute but gives you full control over your orders.</p>
         <button class="btn btn-primary"  >Register Now</button>
        </div>
      </div>
    </div>
   
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AccountSignIn',
   data () {
        return {
            valid: true,
            email: '',
            emailRules: [
            v => !!v || 'E-mail is required',
            v => /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) || 'E-mail must be valid'
            ],
            showPassword: false,
            password: '',
            passwordRules: [
            v => !!v || 'Password required.',
            v => (v && v.length >= 6) || 'Min 6 characters',
            ],
        }
    },
    computed: {
      ...mapGetters({
            countCart : 'cart/count',
            guest : 'auth/guest',
            user : 'auth/user',
            dialogStatus  : 'dialog/status',
            currentComponent: 'dialog/component',
            prevUrl : 'prevUrl', 
      }),
    },
    methods: {
        ...mapActions({
            setDialogStatus     : 'dialog/setStatus',
            setDialogComponent  : 'dialog/setComponent',
            setAuth             : 'auth/set',
            setAlert            : 'alert/set',
        }),
        showPass(){
          this.showPassword = !this.showPassword;
        },
        // hidePass(){
        //   this.showPassword = false;
        // },

        submit () {
            // if (this.$refs.form.validate()) {
                let formData = {
                    'email' : this.email,
                    'password' : this.password
                }

                this.axios.post('/login', formData)
                .then((response) => {
                    let { data } = response.data
                    this.setAuth(data)
                    if(this.user.id>0){
                        this.setAlert({
                            status : true,
                            color  : 'success',
                            text  : 'Login success',
                        })
                        if(this.prevUrl.length>0) this.$router.push(this.prevUrl) // tambahkan ini
                        this.close()
                    }
                    else{
                        this.setAlert({
                            status : true,
                            color  : 'danger',
                            text  : 'Login failed',
                        })
                    }
                })
                .catch((error) => {
                    let { data } = error.response
                    this.setAlert({
                        status : true,
                        color  : 'danger',
                        text  : data.message,
                    })
                })
            // }
        },
        close() {
            this.$emit('closed', false)
        }
    },

}
</script>
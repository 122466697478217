import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

// Shop
import ShopDetail from '../views/shop/ShopDetail.vue'
import ShopSubcategory from '../views/shop/ShopSubcategory.vue'
import ShopSubjewelry from '../views/shop/ShopSubjewelry.vue'
import ShopSearchResult from '../views/shop/ShopSearchResult.vue'
import ShopCart from '../views/shop/ShopCart.vue'

// Account
import AccountWishlist from '../views/account/AccountWishlist.vue'
import AccountOrders from '../views/account/AccountOrders.vue'
import AccountTickets from '../views/account/AccountTickets.vue'
import AccountProfile from '../views/account/AccountProfile.vue'
import AccountAddress from '../views/account/AccountAddress.vue'
import AccountPayment from '../views/account/AccountPayment.vue'
import AccountSignIn from '../views/account/AccountSignin.vue'

// Checkout
import CheckoutDetails from '../views/checkout/CheckoutDetails.vue'
import CheckoutReview from '../views/checkout/CheckoutReview.vue'
import CheckoutShipping from '../views/checkout/CheckoutShipping.vue'
// import CheckoutPayment from '../views/checkout/CheckoutPayment.vue'
import Payment from '../views/Payment.vue'

Vue.use(Router)

// const routes = [
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
 
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/informasi',
    name: 'Informasi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/help/Informasi.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contacts.vue')
  },
  {
    path: '/success',
    name: 'Success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Success.vue')
  },
  {
    path: '/diamonds',
    props: true,
    name: 'Diamonds',
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopDiamond.vue')
  },
  {
    path: '/jewelry',
    props: true,
    name: 'Jewelry',
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopJewelry.vue')
  },
  {
    path: '/gemstones',
    props: true,
    name: 'Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopGridLs.vue')
  },
  {
    path: '/gemstones/:id/:slug',
    props: true,
    name: 'ShopSubcategory',
        component: ShopSubcategory
  },
  {
    path: '/jewelry/:id/:slug',
    props: true,
    name: 'ShopSubjewelry',
        component: ShopSubjewelry
  },
    {
    path: '/order-tracking',
    name: 'Order Tracking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderTracking.vue')
  },
   {
    path: '/cart',
    name: 'Cart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopCart.vue')
  }
  ,
  // SHOP
  {
    path: '/product/detail/:id/:slug',
    props: true,
    name: 'ShopDetail',
    component: ShopDetail
  },
  // {
  //   path: '/gemstones/:id/:slug',
  //   props: true,
  //   name: 'ShopSubcategory',
  //   component: ShopSubcategory
  // },
  {
    path: '/product/cart',
    name: 'ShopCart',
    component: ShopCart,
    
  },
  {
    path: '/product/search/:keyword',
    name: 'ShopSearchResult',
    component: ShopSearchResult,
    
  },
  // ACCOUNT 
  {
    path: '/account/wishlist',
    name: 'AccountWishlist',
    component: AccountWishlist
  },
  {
    path: '/account/orders',
    name: 'AccountOrders',
    component: AccountOrders
  },
  {
    path: '/account/support-tickets',
    name: 'AccountTickets',
    component: AccountTickets
  },
  {
    path: '/account/profile',
    name: 'AccountProfile',
    props:true,
    component: AccountProfile,
    meta: { auth: true }
  },
  {
    path: '/account/address',
    name: 'AccountAddress',
    props:true,
    component: AccountAddress
  },
  {
    path: '/account/payment',
    name: 'AccountPayment',
    component: AccountPayment
  },
  {
    path: '/account/sign-in',
    name: 'AccountSignIn',
    component: AccountSignIn
  },
  // CHECKOUT
  {
    path: '/checkout/details',
    name: 'CheckoutDetails',
    component: CheckoutDetails,
    meta: { auth: true }
  },
  {
    path: '/checkout/review',
    name: 'CheckoutReview',
    component: CheckoutReview
  },
  {
    path: '/checkout/shipping',
    name: 'CheckoutShipping',
    component: CheckoutShipping
  },
  {
    path: '/checkout/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/checkout/complete',
    name: 'CheckoutComplete',
    component: () => import(/* webpackChunkName: "about" */ '../views/checkout/CheckoutComplete.vue')
  },
  {
    path: '/birthstones',
    name: 'Birthstones',
    component: () => import(/* webpackChunkName: "about" */ '../views/Birthstones.vue')
  },
  {
    path: '/custom-jewelry',
    name: 'CustomJewelry',
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomJewelry.vue')
  },
  {
    path: '/custom-jewelry/design',
    name: 'JewelryDesign',
    component: () => import(/* webpackChunkName: "about" */ '../views/JewelryDesign.vue')
  },
  {
    path: '/birthstones/january',
    name: 'JanuaryBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/JanuaryBirthstone.vue')
  },
  {
    path: '/birthstones/february',
    name: 'FebruaryBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/FebruaryBirthstone.vue')
  },
  {
    path: '/birthstones/march',
    name: 'MarchBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/MarchBirthstone.vue')
  },
  {
    path: '/birthstones/april',
    name: 'AprilBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/AprilBirthstone.vue')
  },
  {
    path: '/birthstones/may',
    name: 'MayBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/MayBirthstone.vue')
  },
  {
    path: '/birthstones/june',
    name: 'JuneBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/JuneBirthstone.vue')
  },
  {
    path: '/birthstones/july',
    name: 'JulyBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/JulyBirthstone.vue')
  },
  {
    path: '/birthstones/august',
    name: 'AugustBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/AugustBirthstone.vue')
  },
  {
    path: '/birthstones/september',
    name: 'SeptemberBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/SeptemberBirthstone.vue')
  },
  {
    path: '/birthstones/october',
    name: 'OctoberBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/OctoberBirthstone.vue')
  },
  {
    path: '/birthstones/november',
    name: 'NovemberBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/NovemberBirthstone.vue')
  },
  {
    path: '/birthstones/december',
    name: 'DecemberBirthstone',
    component: () => import(/* webpackChunkName: "about" */ '../views/birthstones/DecemberBirthstone.vue')
  },
  { path: '*', redirect: '/' }
]
})

router.beforeEach((to, from, next) => {
  // jika routing ada meta auth-nya maka
  if (to.matched.some(record => record.meta.auth)) {
    // jika user adalah guest
    if(store.getters['auth/guest']){
      // tampilkan pesan bahwa harus login dulu
      // store.dispatch('alert/set', {
      //   status : true,
      //   text  : 'Login first',
      //   color  : 'error',
      // })
      // store.dispatch('setPrevUrl', to.path) 
      // tampilkan form login
      // store.dispatch('dialog/setComponent', '/account/sign-in')
      next('/account/sign-in')
    }
    else{
      next()
    } 
  }
  else{
      next()
  }
})


// const router = new VueRouter({
//   routes
// })

export default router

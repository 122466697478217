<template>
<div class="accountaddress">
  <AddressEditAdd />
<AddressNewAdd />

<!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap"><a href="#">Account</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Addresses</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">My addresses</h1>
        </div>
      </div>
    </div>

    <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-3">
      <div class="row">

        <!-- Sidebar-->
        <AccountSidebarMenu />

        <!-- Content  -->
        <section class="col-lg-8">
          <!-- Toolbar-->
          <div class="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-4">
            <h6 class="font-size-base text-light mb-0">List of your registered addresses:</h6><a class="btn btn-primary btn-sm" href="account-signin.html"><i class="czi-sign-out mr-2"></i>Sign out</a>
          </div>
          <!-- Addresses list-->
          <div v-if="addresses.length>0" class="table-responsive font-size-md">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th>Alias</th>
                  <th>Address</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(address, index) in addresses" :key="address.id">
                  <td class="py-3 align-middle">{{ address.alias }}</td>
                  <td class="py-3 align-middle">{{ address.address_line1 }}<span class="align-middle badge badge-info ml-2" v-if="address.is_default==1">Primary</span></td>
                  <!-- <td class="py-3 align-middle"><a class="nav-link-style mr-2" @click="editAddress(index, address.id)"  data-toggle="modal" title="Edit"> -->
                  <td class="py-3 align-middle"><a class="nav-link-style mr-2" href="#edit-address" @click="editAddress(index, address.id)"  data-toggle="modal" title="Edit">
                    <i class="czi-edit"></i></a>
                    <a class="nav-link-style text-danger" @click="removeAddress(index, address.id)" data-toggle="tooltip" title="Remove"><div class="czi-trash"></div></a>

                    <!-- <button class="btn btn-outline-danger btn-sm px-4" type="button" @click="removeAddress(index,address.id )"><i class="czi-trash mr-2"></i>Remove</button> -->
                    
                    </td>
                </tr>
              
              </tbody>
            </table>
          </div>

           <div v-else class="d-sm-flex justify-content-between mt-lg-4 mb-4 pb-3 pb-sm-2 ">
            <div class="alert alert-warning w-100" role="alert">
              {{info}}
            </div>
           
          </div>

          <hr class="pb-4">
          <div class="text-sm-right"><a class="btn btn-primary" href="#add-address" data-toggle="modal">Add new address</a></div>
        </section>
      </div>
    </div>
    </div>
</template>

<script>
import AccountSidebarMenu from '@/components/AccountSidebarMenu.vue'
import { mapGetters, mapActions } from 'vuex'
import AddressNewAdd from '@/components/AddressNewAdd.vue'
import AddressEditAdd from '@/components/AddressEditAdd.vue'

export default {
  name: 'AccountAddress',
  components: {
    AccountSidebarMenu,
    AddressNewAdd,
    AddressEditAdd

  },
  data(){
    return{   
      addresses:[],
      info: null,
      addressedit_id:0
    }
  },
  computed: {
        ...mapGetters({
          user    : 'auth/user',
          carts: 'cart/carts',
    }),

    // options () {
    //   return {
    //     keys: [
    //       'name,'
    //       'slug'
    //     ],
    //     includeScore: this.includeScore,
    //   }
    // },
  },
  methods: {
    ...mapActions({
        addCart   : 'cart/add',
        setAlert  : 'alert/set',
      }),
      editAddress(index, id){

          this.addressedit_id=id
          
      },
      removeAddress(index, id){
   
        let config = {
        headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }

        let formData = {
            'id' : id,
        }
      
        this.axios.post('/remove-address/'+id, formData, config)
           .then((response) => {
            let {data } = response.data
            this.addresses.splice(index, 1);
            this.setAlert({
                status : true,
                text  : data.message,
                color  : 'success',
            })
          })
        .catch((error) => {
            let {data} = error.response
            this.setAlert({
                status : true,
                text  : data.message,
                color  : 'danger',
          }) })

      },
  },
  mounted() {
    let config = {
      headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
      },
    }
    

    this.axios.get('/my-address',config)
      .then((response) => {
        this.info = response.data.message
        let {data } = response.data
        this.addresses = data
      })
      .catch((error) => {
        let {data} = error.response
        this.setAlert({
            status : true,
            text  : data.message,
            color  : 'danger',
      }) })

},

}
</script>
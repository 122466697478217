<template>
    <div class="absolute w-100 d-flex justify-content-center" >
        <search-focus @keyup="focusSearch"></search-focus>

        <div class="input-group-overlay d-flex justify-content-center" >
            <input class="form-control appended-form-control" type="text" placeholder="Search for products" 
            v-model="keyword"
            @blur="searchResultsVisible = false" 
            @focus="searchResultsVisible = true"
            @keydown.esc="searchResultsVisible = false"
            @input="softReset"
            ref="search"
            @keydown="doSearch"
            >
            <div class="input-group-append-overlay" v-if="keyword.length==0"><span class="input-group-text"><i class="czi-search"></i></span></div>
             <div class="input-group-append-overlay text-2xl cursor-pointer text-gray-600 hover:text-gray-800" v-if="keyword.length>0" @click="reset" style="pointer-events: auto;"><span class="input-group-text"><i class="czi-close"></i></span></div>
        
        <div 
         class="bg-light border left-0 right-0 text-left w-100 mb-4 mt-5 p-3 rounded-lg shadow " style="max-height: 18rem;position:absolute;z-index:10;overflow-y: scroll" v-if="keyword.length>0 && searchResultsVisible==true">

            <div class="alert alert-warning" role="alert" v-if="searchResults.length==0 && keyword.length>0">
            <p><small>No results for "{{ keyword }}"</small></p>
            </div>
       
            <a
        class="pb-2" v-for="(product, index) in searchResults" :key="index" 
                :href="'/product/search/'+product.slug" @mousedown.prevent="searchResultsVisible = true"><h6 class="widget-product-title">{{ product.name }}</h6></a>
                       
                <!-- <div class="media align-items-center">
                    <router-link class="d-block mr-2" v-bind:to="'/product/detail/'+product.id+'/'+product.slug"><img width="64" :src="product.galleries[0].photo" alt="Product"/></router-link>
                    <div class="media-body">
                    <h6 class="widget-product-title"><router-link v-bind:to="'/product/detail/'+product.id+'/'+product.slug" >{{ product.name }}</router-link></h6>
                    <div class="widget-product-meta"><span class="text-accent mr-2">Rp {{ product.price.toLocaleString('id-ID') }}</span></div>
                    </div>
                </div> -->
            </div>
       
        </div>

        
        
        </div>

</template>

<script>
import SearchFocus from './SearchFocus.vue'


export default {
    name: 'Search',
    components: {
        SearchFocus,
        // ProductItem: () => import(/* webpackChunkName: "product-item" */ '@/components/ProductItem.vue')
    },
    data () {
        return {
             
            keyword: '',
            test_keydown:false,
            searchResultsVisible: false,
            dropResultsVisible: false,
            products: [],
            searchResults: [],
            options: {
                shouldSort: true,
                includeMatches: true,
                threshold: 0.5,
                location: 0,
                distance: 500,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: ['name']
            },
            to:''
        }
    },
    created(){
        let uri = '/products/search'
        this.axios.get(uri)  
        .then(response => {
            this.products = response.data.data
        })
      
    },
    methods: {
        doSearch(){
            let keyword = this.keyword
            if(keyword.length>0){
                this.axios.get('/products/search/'+keyword)
                .then((response) => {
                    let { data } = response.data
                    this.searchResults = data.data
                })
                .catch((error) => {
                    console.log(error)
                })
            } else {
                this.searchResults = []
            }
        },
        close() {
            this.$emit('closed', false)
        },
        reset() {
            this.keyword = ''
        },
        softReset() {
            this.searchResultsVisible = true

        },
        focusSearch(e) {
            if(e.key == '/'){
                this.$refs.search.focus()
            }
        },
        performSearch(){
            
            this.$search(this.keyword, this.products, this.options)
                .then(results => {
                    this.test_keydown = true
                this.searchResults = results
                
            })
        },

     

    },
    
}
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.1); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}
</style>

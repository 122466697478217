<template>
<div>
   <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><router-link class="text-nowrap" to="/product"><i class="czi-home"></i>Home</router-link></li>
              <li class="breadcrumb-item text-nowrap"><a href="shop-grid-ls.html">Shop</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Checkout</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">Checkout</h1>
        </div>
      </div>
    </div>
     <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-4">
      <div class="row">
        <section class="col-lg-8">
          <!-- Steps-->
            <div class="steps steps-light pt-2 pb-3 mb-5">

              <!-- <router-link class="step-item active" href="/cart">
              <div class="step-progress"><span class="step-count">1</span></div>
              <div class="step-label"><i class="czi-cart"></i>Cart</div></router-link>
              
              <router-link class="step-item active current" to="/checkout/details">
              <div class="step-progress"><span class="step-count">2</span></div>
              <div class="step-label"><i class="czi-user-circle"></i>Your details</div></router-link> -->
              
              <!-- <router-link class="step-item" to="/checkout/shipping">
              <div class="step-progress"><span class="step-count">3</span></div>
              <div class="step-label"><i class="czi-package"></i>Shipping</div></router-link>
              
              <router-link class="step-item" to="/checkout/payment">
              <div class="step-progress"><span class="step-count">4</span></div>
              <div class="step-label"><i class="czi-card"></i>Payment</div></router-link>
              
              <router-link class="step-item" to="/checkout/review">
              <div class="step-progress"><span class="step-count">5</span></div>
              <div class="step-label"><i class="czi-check-circle"></i>Review</div></router-link> -->
              
            </div>
          <!-- Autor info-->
          <!-- <div class="d-sm-flex justify-content-between align-items-center bg-secondary p-4 rounded-lg mb-grid-gutter">
            <div class="media align-items-center">
              <div class="img-thumbnail rounded-circle position-relative" style="width: 6.375rem;"><span class="badge badge-warning" data-toggle="tooltip" title="Reward points">384</span><img class="rounded-circle" src="img/shop/account/avatar.jpg" alt="Susan Gardner"></div>
              <div class="media-body pl-3">
                <h3 class="font-size-base mb-0">Susan Gardner</h3><span class="text-accent font-size-sm">s.gardner@example.com</span>
              </div>
            </div><a class="btn btn-light btn-sm btn-shadow mt-3 mt-sm-0" href="account-profile.html"><i class="czi-edit mr-2"></i>Edit profile</a>
          </div> -->
          <!-- Shipping address-->
          <!-- <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">Shipping address</h2> -->
          <h2 class="border-bottom" style="margin-top:100px; ">Penerima</h2>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-fn">First Name</label>
                <input class="form-control" type="text" id="checkout-fn" v-model="addressDefault[0].name">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-ln">Last Name</label>
                <input class="form-control" type="text" id="checkout-ln" >
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-email">E-mail Address</label>
                <input class="form-control" type="email" id="checkout-email" v-model="user.email">
              </div>
            </div> -->
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-phone">Phone Number</label>
                <input class="form-control" type="text" id="checkout-phone" v-model="addressDefault[0].phone">
              </div>
            </div>
          </div>
          <h2 class="h6 pt-1 pb-3 mt-4 mb-3 border-bottom">Alamat Pengiriman <small>- (Primary Address)</small></h2> 
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="checkout-address-1">Address 1</label>
                <input class="form-control" type="text" id="checkout-address-1" v-model="addressDefault[0].address_line1">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-province">Province</label>
                <select name="province" class="form-control custom-select" id="checkout-province" aria-readonly  v-model='addressDefault[0].province_id' @change='citiesByProvince()' >
                  <option v-if="addressDefault[0].province_id<1" value="" selected>Choose province</option>
                  <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.province }}</option>
                </select>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-city">City</label>
                <select class="form-control custom-select" id="checkout-city" v-model='addressDefault[0].city_id'>
                  <option value="">Choose city</option>
                  <option class="py-1" v-for="city in citiesByProvince" :key="city.id" :value="city.id">{{ city.city }}</option>
                </select>
              </div>
            </div>

            
          </div>
                  

          <div class="row">
            
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-country">Country</label>
                <select name="country" class="form-control custom-select" id="checkout-country" aria-readonly>
                  <!-- <option>Choose country</option> -->
                  <option value="1">Indonesia</option>
                </select>
              </div>
            </div>
  
          </div>

          <h2 class="h6 pt-1 pb-3 mt-4 mb-3 border-bottom">Courier</h2>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-courier">Courier</label>
                <select class="form-control custom-select" id="checkout-courier" v-model='courier' @change='getServices()' >
                  <option v-if="courier==''" value="" selected>Choose courier</option>
                  <option v-for="courier in couriers" :key="courier.id" :value="courier.id">{{ courier.text }}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-service">Services</label>
                <select class="form-control custom-select" id="checkout-service"  v-model='service' @change='calculateBill()' >
                  <!-- <option value="">Choose services</option> -->
                  <!-- <div v-if="courier!=''"> -->
                  <option  v-for="service in services" :key="service.service" :value="service.service">{{ service.resume }}</option>
                  <!-- </div> -->
            
                </select>
              </div>
            </div>
          </div>
          
          
          <!-- Navigation (desktop)-->
          <div class="d-none d-lg-flex pt-4 mt-3">
            <div class="w-50 pr-3"><router-link class="btn btn-secondary btn-block" to="/product/cart"><i class="czi-arrow-left mt-sm-0 mr-1"></i><span class="d-none d-sm-inline">Back to Cart</span><span class="d-inline d-sm-none">Back</span></router-link></div>
            <!-- <div class="w-50 pl-2"><router-link class="btn btn-primary btn-block" to="/checkout/shipping"><span class="d-none d-sm-inline">Proceed to Shipping</span><span class="d-inline d-sm-none">Next</span><i class="czi-arrow-right mt-sm-0 ml-1"></i></router-link></div> -->
             <div class="w-50 pl-2"><button class="btn btn-primary btn-block" @click="pay" ><span class="d-none d-sm-inline">Payment</span><span class="d-inline d-sm-none">Next</span><i class="czi-arrow-right mt-sm-0 ml-1"></i></button></div>
          </div>
        </section>
       <!-- sidebar -->
       <!-- Sidebar-->
        <aside class="col-lg-4 pt-4 pt-lg-0">
          <div class="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
            <div class="widget mb-3" v-if="countCart>0">

              <h2 class="widget-title text-center">Order summary</h2>

              <div class="media align-items-center pb-2 border-bottom"  v-for="(item, index) in carts" :key="'cart'+index" >
                <router-link class="d-block mr-2" v-bind:to="'/product/detail/'+item.id+'/'+item.slug"><img width="64" :src="item.photo" alt="Product"/></router-link>
                <div class="media-body">
                  <h6 class="widget-product-title"><router-link v-bind:to="'/product/detail/'+item.id+'/'+item.slug"  >{{ item.name }}</router-link></h6>
                  <div class="widget-product-meta"><span class="text-accent mr-2">Rp {{ item.price.toLocaleString('id-ID') }} </span><span class="text-muted">x {{ item.quantity }} </span></div>
                </div>
              </div>
         
            </div>

            <div class="widget mb-3" v-else>
                <div class="media align-items-center">
                  Shopping cart empty!
                </div>
            </div>
            <ul class="list-unstyled font-size-sm pb-2 border-bottom">
              <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Subtotal:</span><span class="text-right">Rp {{ totalPrice.toLocaleString('id-ID') }}</span></li>
              <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Shipping:</span><span class="text-right">Rp. {{ shippingCost.toLocaleString('id-ID') }}</span></li>
              <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Discount:</span><span class="text-right">—</span></li>
            </ul>
           
            <h3 class="font-weight-normal text-center mt-4">Rp {{ totalBill.toLocaleString('id-ID') }}</h3>
             <div class="text-center mb-5">
                <p>
                  <small>Products Total: {{ totalQuantity }} items</small>
              </p>
            </div>
            <!-- <form class="needs-validation" method="post" novalidate>
              <div class="form-group">
                <input class="form-control" type="text" placeholder="Promo code" required>
                <div class="invalid-feedback">Please provide promo code.</div>
              </div>
              <button class="btn btn-outline-primary btn-block" type="submit">Apply promo code</button>
            </form> -->
          </div>
        </aside>
       <!-- <CheckoutOrderSummary /> -->

      </div>
      <!-- Navigation (mobile)-->
      <div class="row d-lg-none">
        <div class="col-lg-8">
          <div class="d-flex pt-4 mt-3">
            <div class="w-50 pr-3"><router-link class="btn btn-secondary btn-block" to="/product/cart"><i class="czi-arrow-left mt-sm-0 mr-1"></i><span class="d-none d-sm-inline">Back to Cart</span><span class="d-inline d-sm-none">Back</span></router-link></div>
            <!-- <div class="w-50 pl-2"><router-link class="btn btn-primary btn-block" to="/checkout/shipping"><span class="d-none d-sm-inline">Proceed to Shipping</span><span class="d-inline d-sm-none">Next</span><i class="czi-arrow-right mt-sm-0 ml-1"></i></router-link></div> -->
            <div class="w-50 pl-2"><router-link class="btn btn-primary btn-block" to="/checkout/shipping"><span class="d-none d-sm-inline">Payment</span><span class="d-inline d-sm-none">Next</span><i class="czi-arrow-right mt-sm-0 ml-1"></i></router-link></div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import CheckoutOrderSummary from '@/components/CheckoutOrderSummary.vue'

export default {
  name: 'CheckoutDetails',
  components: {
    // CheckoutOrderSummary

  },
  data() {
    return {
        name: '',
        address: '',
        phone: '',
        province_id: 0,
        city_id: 0,
        courier: '',
        couriers: [],
        service: '',
        services: [],
        shippingCost: 0,
        totalBill: 0,
        dialogConfirm: false,
        addressDefault:{},
  
      }
    
  },
  computed: {
      ...mapGetters({
        user        : 'auth/user',
        provinces   : 'region/provinces',
        cities      : 'region/cities',
        // tambahkan ini
        carts         : 'cart/carts',
        countCart     : 'cart/count',
        totalPrice    : 'cart/totalPrice',
        totalQuantity : 'cart/totalQuantity',
        totalWeight   : 'cart/totalWeight',
      }),
      citiesByProvince(){
        let province_id = this.addressDefault[0].province_id
        return this.cities.filter((city) => {           
            if (city.province_id==province_id) return city
        })
      },
    },
    methods: {
      ...mapActions({
        setAlert      : 'alert/set',
        setAuth       : 'auth/set',
        setProvinces  : 'region/setProvinces',
        setCities     : 'region/setCities',
        setCart       : 'cart/set',
        setPayment    : 'setPayment'
      }),
      saveShipping(){
        let formData = new FormData()
        formData.set('name', this.addressDefault[0].name)
        formData.set('address', this.addressDefault[0].address_line1)
        formData.set('phone', this.addressDefault[0].phone)
        formData.set('province_id', this.addressDefault[0].province_id)
        formData.set('city_id', this.addressDefault[0].city_id)
        
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }

        this.axios.post('/shipping', formData, config)
          .then((response) => {
              let { data } = response
              this.setAuth(data.data) 
              this.setAlert({
                  status : true,
                  text  : data.message,
                  color  : 'success',
              })
          })
          .catch((error) => {
              let { data } = error
              this.setAlert({
                  status : true,
                  text  : data.message,
                  color  : 'danger',
              })
          })
      },
      getServices(){
        let courier = this.courier
        let encodedCart = JSON.stringify(this.carts)
        // console.log(encodedCart)
        let formData = new FormData()
        formData.set('courier', courier)
        formData.set('carts', encodedCart);
        
        let config = {
            headers: {
                'Authorization': 'Bearer ' + this.user.api_token,
            },
        }
        this.axios.post('/services', formData, config)
            .then((response) => {
                let response_data = response.data
                // jika tidak error maka data service dan cart akan diupdate.
                if(response_data.status!='error'){
                    this.services = response_data.data.services
                    this.setCart(response_data.data.safe_carts)
                }

                this.setAlert({
                    status : true,
                    text  : response_data.message,
                    color  : response_data.status,
                })
            })
            .catch((error) => {
                let responses = error.response
                this.setAlert({
                    status : true,
                    text  : responses.data.message,
                    color  : 'danger',
                })
            })
        },
        calculateBill(){
            let selectedService = this.services.find((service) => {
                return (service.service==this.service)
            })
            this.shippingCost = selectedService.cost
            this.totalBill = parseInt(this.totalPrice) + parseInt(this.shippingCost)
        },
        pay(){
            this.dialogConfirm = false
            let courier = this.courier
            let service = this.service
            let safeCart = JSON.stringify(this.carts)
            let formData = new FormData()
            formData.set('courier', courier)
            formData.set('service', service)
            formData.set('carts', safeCart);
            let config = {
                headers: {
                'Authorization': 'Bearer ' + this.user.api_token,
                },
            }
            this.axios.post('/payment', formData, config)
                .then((response) => {
                let { data } = response
                if(data && data.status=='success'){
                    this.setPayment(data.data) // <= ini
                    this.$router.push({path: "/payment"})
                    this.setCart([])
                }

                this.setAlert({
                    status : true,
                    text  : data.message,
                    color  : data.status,
                })
            })
            .catch((error) => {
                let { data } = error.response
                this.setAlert({
                    status : true,
                    text  : data.message,
                    color  : 'danger',
                })
            })
        },
        cancel(){
            this.dialogConfirm = false
        }
    },
    created(){
        this.name = this.user.name
        this.name = this.user.email
        this.address = this.user.address
        this.phone = this.user.phone
        this.city_id = this.user.city_id
        this.province_id = this.user.province_id

        if(this.provinces && this.provinces.length==0){
            this.axios.get('/provinces')
            .then((response) => {
                let { data } = response.data
                this.setProvinces(data)
                
            })

            this.axios.get('/cities')
            .then((response) => {
                let { data } = response.data
                this.setCities(data)
            })
        }

        if(this.couriers &&  this.couriers.length==0){
            this.axios.get('/couriers')
            .then((response) => {
                this.couriers = response.data.data
            })
            this.axios.get('/services')
            .then((response) => {
                this.services = response.data.data.data
            })
        }
    },
    mounted(){
        let config = {
      headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
      },
    }
    

    this.axios.get('/default-address',config)
      .then((response) => {
        this.info = response.data.message
        let {data } = response.data
        this.addressDefault = data
        // this.address_line1 = data.data.address_line1
        // this.address_line2 = data.address_line2
        // this.address_line3 = data.address_line3
      })
      .catch((error) => {
        let {data} = error.response
        this.setAlert({
            status : true,
            text  : data.message,
            color  : 'danger',
      }) })

    }



  // methods: {
  //   removeItem(index) {
  //     this.keranjangUser.splice(index,1);
  //     const parsed = JSON.stringify(this.keranjangUser);
  //     localStorage.setItem('keranjangUser', parsed);
  //   },
  //   checkout(){
  //     let productIds = this.keranjangUser.map(function(product){
  //       return product.id
  //     });

  //     let checkoutData = {
  //       'name': this.customerInfo.name, 
  //       'email': this.customerInfo.email,
  //       'number': this.customerInfo.number,
  //       'address': this.customerInfo.address,
  //       "transaction_total": this.totalHarga,
  //       "transaction_status": "PENDING",
  //       "transaction_details": productIds
  //     };

  
  //      axios
  //       .get("http://bmsdata.mitra1001.com/api/checkout", checkoutData)
  //       .then(() => this.$router.push('success'))
  //       //  eslint-disable-next-line no-console
  //       .catch(err => console.log(err));


  //   }
  // },
  // mounted() {
  //     if (localStorage.getItem('keranjangUser')) {
  //     try {
  //       this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
  //     } catch(e) {
  //       localStorage.removeItem('keranjangUser');
  //     }
  //   }
  // },
  // computed: {
  //   totalHarga() {
  //     return this.keranjangUser.reduce(function(items, data){
  //       return items + data.price;
  //     },0);
  //   }
  // }

}
</script>